import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { useParams } from 'react-router-dom'
import { useUserDispatch, authenticateEnterpriseUser } from "../../context/UserContext";
import { withRouter } from "react-router-dom";
import useStyles from "./styles";
import { useDispatch } from "react-redux";
import { APIStatus } from '../../app/APIStatus';

function RedirectPage(props) {
  var classes = useStyles();
  var userDispatch = useUserDispatch();
  const defaultLogoImg = `${process.env.PUBLIC_URL}/icon.png`;
  const [logoImage, setlogoImage] = useState(defaultLogoImg);

  const { token, org, user } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: APIStatus.closeSession });
    authenticateEnterpriseUser(userDispatch, token, org, user, props)
    //eslint-disable-next-line
  }, [])

  return (
    <Grid container style={{ background: '#2d4d76' }} className={classes.container}>
      <Paper style={{ background: 'white' }} classes={{ root: classes.paperRoot }}>
        <img
          src={logoImage}
          className={classes.logotypeIcon}
          alt="logo"
        />
        {/* <img className={classes.logotypeIcon} src="icon.png" alt="logo" /> */}
        <Typography variant="h5" color="primary" className={classes.textRow}>
          Redirecting to TOP Clear Platform....
        </Typography>
      </Paper>
    </Grid>
  );
}
export default withRouter(RedirectPage);

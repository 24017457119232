import React from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

const UserManagementContext = React.createContext();

const rootReducer = (state, action) => {
    switch (action.type) {
        case "USERS_LIST":
            return {
                isLoaded: true,
                user: action.payload
            };
            case "ORG_LIST":
                return {
                    isLoaded: true,
                    org: action.payload
                };      
    }
}
const UserManagementProvider = ({ children }) => {
    var [user, setUser] = React.useReducer(rootReducer, {
        isLoaded: false,
        user: []
    });
    var [org, setOrg] = React.useReducer(rootReducer, {
        isLoaded: false,
        org: []
    });
    return (
        <UserManagementContext.Provider value={{ user, setUser, org, setOrg }}>
            {children}
        </UserManagementContext.Provider>
    );
};

const useUserManagementState = () => {
    const context = React.useContext(UserManagementContext);
    //console.log(context, UserManagementContext)
    return context;
};

export function getUsers(dispatch, userDispatch, history) {

    return axios.get(`/${sessionStorage.getItem("organization")}/users`).then(res => {
        let userData = res.data.results;
        dispatch({ type: "USERS_LIST", payload: userData });

    }).catch(err => {
        console.log(err);
    });
}
export function getOrg(dispatch,userDispatch, history) {
    return axios.get(`/${sessionStorage.getItem("organization")}/enterprise-org`).then(res => {
        dispatch({ type: "ORG_LIST", payload: res.data.results });
        //console.log('Org List', res.data.results)
    }).catch(err=>{
        //console.log(err);
    });
}

export { UserManagementProvider, UserManagementContext, useUserManagementState };
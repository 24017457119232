import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import { APIStatus } from "./APIStatus";

const initialState = {
  weatherData: null,
  isLoading: APIStatus.loading,
};

export const getweatherData = createAsyncThunk(
  "getweatherData",
  async (payload) => {
    const timeDiff = moment(payload.endDate).diff(
      moment(payload.startDate),
      "days"
    );
    let rainfallDataArr = [];
    if (timeDiff > 30) {
      let noOfCalls = parseInt(timeDiff / 30 + 1);
      let startDate = moment(payload.startDate).format("DD-MM-YYYY");
      let currentDate = moment().format("DD-MM-YYYY");
      for (let i = 0; i < noOfCalls; i++) {
        let endDate = moment(startDate, "DD-MM-YYYY")
          .add(30, "days")
          .format("DD-MM-YYYY");
        if (i === noOfCalls - 1) {
          if (moment(currentDate).isSame(moment(endDate)) === false) {
            endDate = moment(payload.endDate).format("DD-MM-YYYY");
          }
        }
        rainfallDataArr.push(
          axios.get(
            `/premium/v1/past-weather.ashx?key=guv5e6mryzrefewuy4444vc5&q=${payload.postCode}&format=json&date=${startDate}&enddate=${endDate}&tp=1`,
            { baseURL: "https://api.worldweatheronline.com" }
          )
        );
        startDate = moment(startDate, "DD-MM-YYYY")
          .add(30, "days")
          .format("DD-MM-YYYY");
      }
    } else {
      rainfallDataArr.push(
        axios.get(
          `/premium/v1/past-weather.ashx?key=guv5e6mryzrefewuy4444vc5&q=${payload.postCode}&format=json&date=${payload.startDate}&enddate=${payload.endDate}&tp=1`,
          { baseURL: "https://api.worldweatheronline.com" }
        )
      );
    }
    const response = axios.all(rainfallDataArr).then(
      axios.spread((...responses) => {
        let rainFallData = [];
        let responseData = {};
        for (let r = 0; r < responses.length; r++) {
          let res = responses[r];
          res.data.data.weather.forEach((w) => {
            if (w.hourly && w.hourly.length > 0) {
              let date = w.date;
              w.hourly.forEach((wD) => {
                let time = moment(wD.time, "Hmm").format("HH:mm");
                if (time === "Invalid date") {
                  time = moment(wD.time, "HHmm").format("HH:mm");
                }
                let value = wD.precipMM;
                let timeStamp = date + time;
                let dateTime = moment(timeStamp, "YYYY-MM-DDHHmm").format();
                timeStamp = moment(timeStamp, "YYYY-MM-DDHHmm").unix();
                rainFallData.push({ timeStamp, value, dateTime });
              });
            }
          });
        }
        responseData = {
          data: {
            weather: rainFallData,
          },
        };
        return responseData;
      })
    );
    return response;
  }
);

export const getRainFallData = createAsyncThunk(
  "getRainFallData",
  async (payload) => {
    const {
      organization,
      feedKey,
      startTime,
      endTime,
      frequency = 60,
    } = payload;
    const response = await axios
      .get(
        `/${organization}/feeds/${feedKey}/data-stat/${frequency}?completed_at__range=${startTime},${endTime}`
      )
      .then(({ data }) => {
        const rainFallData = data.map((feedData) => {
          const value = feedData[1];
          let timeStamp = feedData[0];
          const dateTime = moment(timeStamp, "YYYY-MM-DDHHmm").format();
          timeStamp = moment(timeStamp, "YYYY-MM-DDHHmm").unix();
          return { timeStamp, value, dateTime };
        });
        return rainFallData;
      })
      .catch((err) => {
        return [];
      });
    const responseData = {
      data: {
        weather: response,
      },
    };
    return responseData;
  }
);

const weatherSlice = createSlice({
  name: "weather",
  initialState,
  reducers: {
    reset(state, action) {
      state.weatherData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getweatherData.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getweatherData.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        state.weatherData = action.payload ? action.payload.data : null;
      })
      .addCase(getRainFallData.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getRainFallData.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        state.weatherData = action.payload ? action.payload.data : null;
      });
  },
});

export const { load, reset } = weatherSlice.actions;

export default weatherSlice.reducer;

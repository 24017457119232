import { combineReducers, configureStore } from "@reduxjs/toolkit";
import alarmsSlice from "./alarmsSlice";
import configurationSlice from "./configurationSlice";
import devicesSlice from "./devicesSlice";
import customDashboardsSlice from "./customDashboardsSlice";
import profileSettingsSlice from "./profileSettingsSlice";
import billingInfoSlice from "./billingInfoSlice";
import meteringDashboardSlice from "./meteringDashboardSlice";
import feedsSlice from "./feedsSlice";
import usersSlice from "./usersSlice";
import processedDataSlice from "./processedDataSlice";
import weatherSlice from "./weatherSlice";
import { APIStatus } from "./APIStatus";
import organizationsSlice from "./organizationsSlice";
import feedRulesSlice from "./feedRulesSlice";
import triggersSlice from "./triggersSlice";
import commentsSlice from "./commentsSlice";
import resetPasswordSlice from "./resetPasswordSlice";
import assetSlice from "./assetSlice";
import edmDashboardSlice from "./edmDashboardSlice";
import diagnosticsSlice from "./diagnosticsSlice";
import rEyeDashboardSlice from "./rEyeDashboardSlice";

const rootReducer = (state, action) => {
  if (action.type === APIStatus.closeSession) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const combinedReducer = combineReducers({
  assetSlice: assetSlice,
  devicesSlice: devicesSlice,
  feedsSlice: feedsSlice,
  usersSlice: usersSlice,
  processedDataSlice: processedDataSlice,
  triggersSlice: triggersSlice,
  alarmsSlice: alarmsSlice,
  weatherSlice: weatherSlice,
  configurationSlice: configurationSlice,
  organizationsSlice: organizationsSlice,
  feedRulesSlice: feedRulesSlice,
  resetPasswordSlice: resetPasswordSlice,
  commentsSlice: commentsSlice,
  customDashboards: customDashboardsSlice,
  profileSettingsSlice: profileSettingsSlice,
  billingInfoSlice: billingInfoSlice,
  meteringDashboardSlice: meteringDashboardSlice,
  edmDashboardSlice: edmDashboardSlice,
  diagnosticsSlice: diagnosticsSlice,
  rEyeDashboardSlice: rEyeDashboardSlice,
});

export const Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  devTools: true,
});

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIStatus } from "./APIStatus";
import { CustomException } from "../util/util";

const initialState = {
  dashboardConfig: null,
  updateDashboardConfig: null,
  updateDashboardConfigError: null,
  deviceFilters: null,
  assetFilters: null,
  deviceSummaryFilters: null,
  alarmsFilters: null,
  emailLogsFilters: null,
  smsLogsFilters: null,
  meteringDashboardFilters: null,
  meteringDetailsFilters: null,
  processedDataFilters: null,
  layerFilters: null,
  isLoading: APIStatus.loading,
  otaSettingsConfigStatus: APIStatus.idle,
  otaSettingsConfig: null,
  otaSettingsConfigError: null,
};

export const getOTASettingsConfig = createAsyncThunk(
  "getOTASettingsConfig",
  async (payload) => {
    const response = await axios.get(
      `/${payload.organization}/config-templates?config_type=app&dType=SNRI&tags__name__in=${payload.tagVersion}`
    );
    return response;
  }
);

export const getDashboardConfig = createAsyncThunk(
  "getDashboardConfig",
  async (payload) => {
    const response = await axios.get(payload.url);
    return response;
  }
);

export const updateDashboardConfig = createAsyncThunk(
  "updateDashboardConfig",
  async (payload) => {
    const response = await axios
      .patch(payload.url, payload.data)
      .then((response) => response)
      .catch((error) => {
        const errorData = JSON.stringify(error.response.data);
        throw new CustomException(errorData);
      });
    return response;
  }
);

const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    reset(state, action) {
      state.deviceFilters = null;
    },
    layerFilterStore(state, action) {
      state.layerFilters = action.payload;
    },
    deviceFilterStore(state, action) {
      state.deviceFilters = action.payload;
    },
    assetFilterStore(state, action) {
      state.assetFilters = action.payload;
    },
    deviceSummaryFilterStore(state, action) {
      state.deviceSummaryFilters = action.payload;
    },
    alarmsFilterStore(state, action) {
      state.alarmsFilters = action.payload;
    },
    emailLogsFilterStore(state, action) {
      state.emailLogsFilters = action.payload;
    },
    smsLogsFilterStore(state, action) {
      state.smsLogsFilters = action.payload;
    },
    meteringDashboardFilterStore(state, action) {
      state.meteringDashboardFilters = action.payload;
    },
    meteringDetailsFilterStore(state, action) {
      state.meteringDetailsFilters = action.payload;
    },
    processedDataFilterStore(state, action) {
      state.processedDataFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardConfig.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getDashboardConfig.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        let payload = action.payload ? action.payload.data : null;
        if (payload) {
          let timezoneForUI = "UTC";

          if (payload.timezone && payload.enforce_timezone) {
            timezoneForUI = payload.timezone;
          }

          payload = { ...payload, timezoneForUI };
        }
        state.dashboardConfig = payload;
      })
      .addCase(getDashboardConfig.rejected, (state, action) => {
        state.isLoading = APIStatus.failed;
        state.dashboardConfig = null;
      })
      .addCase(updateDashboardConfig.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(updateDashboardConfig.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        let payload = action.payload ? action.payload.data : null;
        if (payload) {
          let timezoneForUI = "UTC";

          if (payload.timezone && payload.enforce_timezone) {
            timezoneForUI = payload.timezone;
          }

          payload = { ...payload, timezoneForUI };
        }

        state.updateDashboardConfig = payload;
        state.dashboardConfig = payload;
      })
      .addCase(updateDashboardConfig.rejected, (state, action) => {
        const errorData = JSON.parse(action.error.message);
        state.updateDashboardConfigError = errorData;
        state.updateDashboardConfig = null;
        state.isLoading = APIStatus.failed;
      })
      .addCase(getOTASettingsConfig.pending, (state, action) => {
        state.otaSettingsConfigStatus = APIStatus.loading;
      })
      .addCase(getOTASettingsConfig.fulfilled, (state, action) => {
        state.otaSettingsConfigStatus = APIStatus.loaded;
        state.otaSettingsConfig = action.payload
          ? action.payload.data.results
          : null;
      })
      .addCase(getOTASettingsConfig.rejected, (state, action) => {
        state.otaSettingsConfigStatus = APIStatus.failed;
        state.otaSettingsConfigError = action.error;
      });
  },
});

export const {
  reset,
  layerFilterStore,
  deviceFilterStore,
  assetFilterStore,
  deviceSummaryFilterStore,
  alarmsFilterStore,
  processedDataFilterStore,
  emailLogsFilterStore,
  smsLogsFilterStore,
  meteringDashboardFilterStore,
  meteringDetailsFilterStore,
} = configurationSlice.actions;

export default configurationSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIStatus } from "./APIStatus";

const initialState = {
  devices: null,
  deviceFeedsStatus: APIStatus.idle,
  deviceFeeds: null,
  restoreDeviceFeeds: null,
  deviceFeedsError: null,
  feedData: null,
  assetFeedData: null,
  feedDataLoading: true,
  feedRules: null,
  isLoading: APIStatus.loading,
  addFeedStatus: APIStatus.idle,
  addFeedReceipt: null,
  addFeedError: null,
  updateFeedStatus: APIStatus.idle,
  updateFeedReceipt: null,
  updateFeedError: null,
  deleteFeedStatus: APIStatus.idle,
  deleteFeedReceipt: null,
  deleteFeedError: null,
};

export const getFeeds = createAsyncThunk("getFeeds", async (organization) => {
  const response = await axios.get(`/${organization}/feeds`);
  return response;
});

export const addFeed = createAsyncThunk("addFeed", async (payload) => {
  const response = await axios.post(
    `/${payload.organization}/feeds`,
    payload.data
  );
  return response;
});

export const updateFeed = createAsyncThunk("updateFeed", async (payload) => {
  const response = await axios.patch(
    `/${payload.organization}/feeds/${payload.id}`,
    payload.data
  );
  return response;
});

export const deleteFeed = createAsyncThunk("deleteFeed", async (payload) => {
  const response = await axios.delete(
    `/${payload.organization}/feeds/${payload.id}`
  );
  return response;
});

export const getDeviceFeeds = createAsyncThunk(
  "getDeviceFeeds",
  async (payload) => {
    const response = await axios.get(payload.url);
    return response;
  }
);

export const getRestoreDeviceFeeds = createAsyncThunk(
  "getRestoreDeviceFeeds",
  async (payload) => {
    const response = await axios.get(payload.url);
    return response;
  }
);

export const getDeviceFeedsAndRules = createAsyncThunk(
  "getDeviceFeedsAndRules",
  async (payload) => {
    const feeds = await axios.get(
      `/${payload.organization}/feeds?device__device_id=${payload.id}`
    );
    const feedResp = feeds.data.results;
    const reqArr =
      feedResp &&
      feedResp.map((f) => {
        return axios.get(
          `/${sessionStorage.getItem("organization")}/feeds/rules/?feed=${f.id}`
        );
      });
    const response = await axios.all(reqArr).then(
      axios.spread((...responses) => {
        feedResp.forEach((feed, i) => {
          if (!feed.feedRules) {
            feed.feedRules =
              responses[i] && responses[i].data
                ? responses[i].data.results
                : [];
          }
        });
        return feedResp;
      })
    );
    return response;
  }
);

export const getFeedDataByRange = createAsyncThunk(
  "getFeedDataByRange",
  async (payload) => {
    let feedsDataArr = [];
    let dataInterval = 1;
    if (payload.timeDiff >= 270) {
      dataInterval = 60;
    } else if (payload.timeDiff >= 180) {
      dataInterval = 30;
    } else if (payload.timeDiff >= 90) {
      dataInterval = 10;
    }
    payload.feeds.forEach((feed) => {
      feedsDataArr.push(
        axios.get(
          `/${sessionStorage.getItem("organization")}/feeds/${
            feed.key
          }/data-stat/${dataInterval}?completed_at__range=${
            payload.startDate
          },${payload.endDate}`
        )
      );
    });
    const response = axios.all(feedsDataArr).then(
      axios.spread((...responses) => {
        let feedsData = [];
        for (let r = 0; r < responses.length; r++) {
          let res = responses[r];
          let currentFeedKey = responses[r].config.url.split("/");
          feedsData.push({
            feed: currentFeedKey[3],
            data: res.data,
          });
        }
        return feedsData;
      })
    );
    return response;
  }
);

export const getAssetsDataByRange = createAsyncThunk(
  "getAssetsDataByRange",
  async (payload) => {
    let assetsDataArr = [];
    let dataInterval = 1;
    if (payload.timeDiff >= 270) {
      dataInterval = 60;
    } else if (payload.timeDiff >= 180) {
      dataInterval = 30;
    } else if (payload.timeDiff >= 90) {
      dataInterval = 10;
    }
    payload.feeds.forEach((feed) => {
      assetsDataArr.push(
        axios.get(
          `/${sessionStorage.getItem("organization")}/assets/${
            payload.assetKey
          }/feeds/${feed.key}/data-stat/${dataInterval}?completed_at__range=${
            payload.startDate
          },${payload.endDate}`
        )
      );
    });
    const response = axios.all(assetsDataArr).then(
      axios.spread((...responses) => {
        let assetsData = [];
        for (let r = 0; r < responses.length; r++) {
          let res = responses[r];
          let currentFeedKey = responses[r].config.url.split("/");
          assetsData.push({
            feed: currentFeedKey[5],
            data: res.data,
          });
        }
        return assetsData;
      })
    );
    return response;
  }
);

export const getFeedRules = createAsyncThunk(
  "getFeedRules",
  async (payload) => {
    let rulesDataArr = [];
    if (payload.ruleItems && payload.ruleItems.length > 1) {
      payload.ruleItems.forEach((rule) => {
        rulesDataArr.push(
          axios.get(
            `/${sessionStorage.getItem("organization")}/feeds/rules/?feed=${
              rule.id
            }`
          )
        );
      });
      const response = axios.all(rulesDataArr).then(
        axios.spread((...responses) => {
          let rulesData = [];
          let resultData = {};
          for (let r = 0; r < responses.length; r++) {
            let res = responses[r];
            res.data.results &&
              res.data.results.forEach((result) => {
                rulesData.push(result);
              });
          }
          resultData = {
            data: {
              results: rulesData,
            },
          };
          return resultData;
        })
      );
      return response;
    } else {
      const response = await axios.get(payload.url);
      return response;
    }
  }
);

const feedsSlice = createSlice({
  name: "feeds",
  initialState,
  reducers: {
    reset(state, action) {
      state.feeds = null;
      state.deviceFeeds = null;
    },
    resetFeedList(state, action) {
      state.deviceFeeds = null;
    },
    resetFeedData(state, action) {
      state.feedData = null;
      state.assetFeedData = null;
      state.feedDataLoading = true;
    },
    popupFilters(state, action) {
      state.popupFilters = action.payload;
    },
    resetAddFeed(state, action) {
      state.addFeedStatus = APIStatus.idle;
      state.addFeedReceipt = null;
      state.addFeedError = null;
    },
    resetUpdateFeed(state, action) {
      state.updateFeedStatus = APIStatus.idle;
      state.updateFeedReceipt = null;
      state.updateFeedError = null;
    },
    resetDeleteFeed(state, action) {
      state.updateFeedStatus = APIStatus.idle;
      state.deleteFeedReceipt = null;
      state.deleteFeedError = null;
    },
    resetDeviceFeedData(state) {
      state.feedData = null;
      state.assetFeedData = null;
      state.deviceFeeds = null;
      state.feedRules = null;
      state.weatherData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeeds.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getFeeds.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        state.devices = action.payload ? action.payload.data.results : null;
      })
      .addCase(getDeviceFeeds.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getDeviceFeeds.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        state.deviceFeeds = action.payload ? action.payload.data.results : null;
      })
      .addCase(getRestoreDeviceFeeds.pending, (state, action) => {})
      .addCase(getRestoreDeviceFeeds.fulfilled, (state, action) => {
        state.restoreDeviceFeeds = action.payload
          ? action.payload.data.results
          : null;
      })
      .addCase(getFeedDataByRange.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
        state.feedDataLoading = true;
      })
      .addCase(getFeedDataByRange.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        state.feedData = action.payload;
        state.feedDataLoading = false;
      })
      .addCase(getAssetsDataByRange.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
        state.feedDataLoading = true;
      })
      .addCase(getAssetsDataByRange.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        state.assetFeedData = action.payload;
        state.feedDataLoading = false;
      })
      .addCase(getFeedRules.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getFeedRules.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        state.feedRules = action.payload ? action.payload.data.results : null;
      })
      .addCase(getDeviceFeedsAndRules.pending, (state, action) => {
        state.deviceFeedsStatus = APIStatus.loading;
      })
      .addCase(getDeviceFeedsAndRules.fulfilled, (state, action) => {
        state.deviceFeedsStatus = APIStatus.loaded;
        state.deviceFeeds = action.payload;
      })
      .addCase(getDeviceFeedsAndRules.rejected, (state, action) => {
        state.deviceFeedsStatus = APIStatus.failed;
        state.deviceFeedsError = action.error;
      })
      .addCase(addFeed.pending, (state, action) => {
        state.addFeedStatus = APIStatus.loading;
      })
      .addCase(addFeed.fulfilled, (state, action) => {
        state.addFeedStatus = APIStatus.loaded;
        state.addFeedReceipt = action.payload;
      })
      .addCase(addFeed.rejected, (state, action) => {
        state.addFeedStatus = APIStatus.failed;
        state.addFeedError = action.error;
      })
      .addCase(updateFeed.pending, (state, action) => {
        state.updateFeedStatus = APIStatus.loading;
      })
      .addCase(updateFeed.fulfilled, (state, action) => {
        state.updateFeedStatus = APIStatus.loaded;
        state.updateFeedReceipt = action.payload;
      })
      .addCase(updateFeed.rejected, (state, action) => {
        state.updateFeedStatus = APIStatus.failed;
        state.updateFeedError = action.error;
      })
      .addCase(deleteFeed.pending, (state, action) => {
        state.deleteFeedStatus = APIStatus.loading;
      })
      .addCase(deleteFeed.fulfilled, (state, action) => {
        state.deleteFeedStatus = APIStatus.loaded;
        state.deleteFeedReceipt = "Deleted Successfully";
      })
      .addCase(deleteFeed.rejected, (state, action) => {
        state.deleteFeedStatus = APIStatus.failed;
        state.deleteFeedError = action.error;
      });
  },
});

export const {
  load,
  resetFeedList,
  resetFeedData,
  popupFilters,
  resetAddFeed,
  resetUpdateFeed,
  resetDeviceFeedData,
  resetDeleteFeed,
} = feedsSlice.actions;

export default feedsSlice.reducer;

import React, { Suspense, useEffect, useState } from "react";
import {
  Route,
  Switch,
  withRouter,
  useParams,
  Redirect,
} from "react-router-dom";
import classnames from "classnames";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";

import useStyles from "./styles";
import Header from "../Header";
import EnterpriseHeader from "../EnterpriseHeader/Header";
import EnterpriseSidebar from "../EnterpriseSidebar/Sidebar";
import Sidebar from "../Sidebar";
import { useLayoutState } from "../../context/LayoutContext";
import { usePageTracking } from "./../../usePageTracking";
import { getDevicesHealth } from "../../app/devicesSlice";
import Error from "../../pages/error/Error";
import { APIStatus } from "../../app/APIStatus";
import { UserManagementProvider } from "../../context/UserManagementContext";

import { DashboardProvider } from "../../context/DashboardContext";
import { OrgManagementProvider } from "../../context/OrgManagementContext";

const NewCustomDashboards = React.lazy(() =>
  import("../../pages/new-custom-dashboards")
);
const CustomDashboards = React.lazy(() =>
  import("../../pages/custom-dashboards/CustomDashboards")
);
const Dashboard = React.lazy(() => import("../../pages/dashboard/Dashboard"));
const RemoteEyeDashboard = React.lazy(() =>
  import("../../pages/remoteEyeDashboard/RemoteEyeDashboard")
);
const RemoteEyeDiagnostics = React.lazy(() =>
  import("../../pages/remoteEyeDiagnostics")
);
const Alarms = React.lazy(() => import("../../pages/alarms/Alarms"));
const Triggers = React.lazy(() => import("../../pages/triggers/Triggers"));
const AssetsDashboard = React.lazy(() =>
  import("../../pages/assetsDashboard/AssetsDashboard")
);
const AssetsManagement = React.lazy(() =>
  import("../../pages/assetsManagement/AssetsManagement")
);
const AddAsset = React.lazy(() =>
  import("../../pages/assetsManagement/AddAsset")
);
const EditAsset = React.lazy(() =>
  import("../../pages/assetsManagement/EditAsset")
);
const ScheduleReport = React.lazy(() =>
  import("../../pages/scheduleReport/ScheduleReport")
);
const ProcessedData = React.lazy(() =>
  import("../../pages/processedData/ProcessedData")
);
const DeviceManagement = React.lazy(() =>
  import("../../pages/device-management/DeviceManagement")
);
const AccountSettings = React.lazy(() =>
  import("../../pages/accountSettings/AccountSettings")
);
const MeteringDashboard = React.lazy(() =>
  import("../../pages/meteringDashboard/MeteringDashboard")
);
const EDMDashboard = React.lazy(() =>
  import("../../pages/edmDashboard/EDMDashboard")
);
const UserManagement = React.lazy(() =>
  import("../../pages/userManagement/UserManagement")
);
const EntUserManagement = React.lazy(() =>
  import("../../pages/entUserManagement/UserManagement")
);
const AddDevice = React.lazy(() =>
  import("../../pages/device-management/AddDevice")
);
const EditDevice = React.lazy(() =>
  import("../../pages/device-management/EditDevice")
);
const DeviceSummary = React.lazy(() =>
  import("../../pages/deviceSummary/DeviceSummary")
);
const AssetSummary = React.lazy(() =>
  import("../../pages/assetSummary/AssetSummary")
);
const ChangePassword = React.lazy(() =>
  import("../../pages/changePassword/ChangePassword")
);
const EnterpriseDashboard = React.lazy(() =>
  import("../../pages/enterpriseDashboard/EnterpriseDashboard")
);
const OrgManagement = React.lazy(() =>
  import("../../pages/OrgManagement/OrgManagement")
);
const UserDetails = React.lazy(() =>
  import("../../pages/UserDetails/UserDetails")
);
const OrgDetails = React.lazy(() =>
  import("../../pages/OrgDetails/OrgDetails")
);
const UserActivity = React.lazy(() =>
  import("../../pages/UserActivity/UserActivity")
);
const DeviceDetails = React.lazy(() =>
  import("../../pages/DeviceDetails/DeviceDetails")
);

function Layout(props) {
  var classes = useStyles();
  var layoutState = useLayoutState();

  //const isEnterpriseUser = JSON.parse(sessionStorage.getItem("isEnterpriseUser")) ?? false;

  const dispatch = useDispatch();
  usePageTracking();

  const { isLoading: dashboardConfigAPIStatus, dashboardConfig } = useSelector(
    (state) => state.configurationSlice
  );

  const [isRemoteeyeUser, setIsRemoteeyeUser] = useState(false);

  useEffect(() => {
    setIsRemoteeyeUser(
      dashboardConfig?.meta_data?.custom_config?.is_remoteeye_user?.value ||
        false
    );
  }, [dashboardConfig]);

  const getDashboardTypeToShow = () => {
    if (dashboardConfigAPIStatus !== APIStatus.loading && dashboardConfig) {
      const isRemoteeyeUser =
        dashboardConfig?.meta_data?.custom_config?.is_remoteeye_user?.value ||
        false;

      return isRemoteeyeUser ? <RemoteEyeDashboard /> : <Dashboard />;
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5%",
        }}
      >
        <CircularProgress size={24} />
      </div>
    );
  };

  useEffect(() => {
    const organization = sessionStorage.getItem("organization");
    if (organization) {
      let devicesHealthApi = `/${organization}/devices-health/`;
      dispatch(getDevicesHealth({ url: devicesHealthApi }));
    }
  }, [dispatch]);

  const { dashboardType, type } = useParams();

  useEffect(() => {
    if (dashboardType) {
      sessionStorage.setItem("dashboardType", type);
    }
  }, []);

  const isEnterpriseUser = sessionStorage.getItem("dashboardType")
    ? sessionStorage.getItem("dashboardType") === "enterprise"
      ? true
      : false
    : false;

  return (
    <div className={classes.root}>
      <>
        {isEnterpriseUser ? (
          <EnterpriseHeader history={props.history} />
        ) : (
          <Header history={props.history} />
        )}
        {isEnterpriseUser ? <EnterpriseSidebar /> : <Sidebar />}

        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <Box minHeight={64} />
          <Suspense
            fallback={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5%",
                }}
              >
                <CircularProgress size={24} />
              </div>
            }
          >
            <Switch>
              <Route path="/app/dashboard">
                {isEnterpriseUser ? (
                  <DashboardProvider>
                    <EnterpriseDashboard />
                  </DashboardProvider>
                ) : (
                  getDashboardTypeToShow()
                )}
              </Route>
              {isRemoteeyeUser && (
                <Route path="/app/diagnostics">
                  <RemoteEyeDiagnostics />
                </Route>
              )}
              {/* <Route path="/app/enterprisedashboard">
                <DashboardProvider>
                  <EnterpriseDashboard />
                </DashboardProvider>  
              </Route> */}
              <Route path="/app/alarms">
                <Alarms />
              </Route>
              <Route path="/app/triggers">
                <Triggers />
              </Route>
              <Route path="/app/assetsDashboard">
                <AssetsDashboard />
              </Route>
              <Route path="/app/assetsManagement">
                <AssetsManagement />
              </Route>
              <Route path="/app/addAsset">
                <AddAsset />
              </Route>
              <Route path="/app/editAsset/:assetId">
                <EditAsset />
              </Route>
              <Route path="/app/processedData">
                <ProcessedData />
              </Route>
              <Route path="/app/deviceManagement/manageDevices">
                <DeviceManagement />
              </Route>
              <Route path="/app/deviceManagement/addDevice">
                <AddDevice />
              </Route>
              <Route path="/app/deviceManagement/editDevice/:deviceId">
                <EditDevice />
              </Route>
              <Route path="/app/deviceSummary/:deviceId">
                <DeviceSummary />
              </Route>
              <Route path="/app/assetSummary/:deviceId">
                <AssetSummary />
              </Route>
              <Route path="/app/changePassword">
                <ChangePassword />
              </Route>
              <Route exact path="/app/accountSettings">
                <Redirect to="/app/accountSettings/0" />
              </Route>
              <Route path="/app/accountSettings/:activeTab">
                <AccountSettings />
              </Route>
              <Route path="/app/meteringDashboard">
                <MeteringDashboard />
              </Route>
              <Route path="/app/edmDashboard">
                <EDMDashboard />
              </Route>
              <Route path="/app/userManagement">
                <UserManagement />
              </Route>
              <Route path="/app/CustomDashboards/:selectedDashboardKey">
                <CustomDashboards />
              </Route>
              <Route path="/app/CustomDashboardsNew/:selectedDashboardKey">
                <NewCustomDashboards />
              </Route>
              <Route path="/app/scheduleReports">
                <ScheduleReport />
              </Route>
              {/* Enterprise routes */}
              <Route path="/app/OrgManagement">
                <OrgManagementProvider>
                  <OrgManagement />
                </OrgManagementProvider>
              </Route>
              <Route path="/app/UserManagementEnterprise">
                <UserManagementProvider>
                  <EntUserManagement />
                </UserManagementProvider>
              </Route>
              <Route path="/app/UserDetails" component={UserDetails} />
              <Route path="/app/OrgDetails" component={OrgDetails} />
              <Route path="/app/UserActivity" component={UserActivity} />
              <Route path="/app/DeviceDetails" component={DeviceDetails} />
              <Route path="*">
                <Error />
              </Route>
            </Switch>
          </Suspense>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);

import { makeStyles } from "@mui/styles";

const drawerWidth = 240;

export default makeStyles(theme => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    overflowY: 'overlay',
    width: drawerWidth,
  },
  drawerClose: {
    overflowX: "hidden",
    width: '72px',
  },
  toolbar: {
    toolbar: {
      minHeight: 64,
      backgroundColor: '#f5f5f5',
      color: '#333',
    },
  },
  content: {
    flexGrow: 1,
    padding: '4px',
  },
   sidebarList: {
    marginTop: '10px',
    paddingLeft : '2px',
    paddingRight : '4px',
  }, 
  mobileBackButton: {
    marginTop: '4px',
    marginLeft: '2px',
   },
}));

import React from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { signOut } from "./UserContext";

const OrgManagementContext = React.createContext();

const rootReducer = (state, action) => {
    switch (action.type) {
        case "ORG_LIST":
            return {
                isLoaded: true,
                org: action.payload
            };  
            case "ORG_GROUP_LIST":
            return {
                isLoaded: true,
                org: action.payload
            };     
            case "USER_NAME":
                return {
                    isLoaded: true,
                    org: action.payload
                };                        
    }
}
const OrgManagementProvider = ({ children }) => {
    var [org, setOrg] = React.useReducer(rootReducer, {
        isLoaded: false,
        org: []
    });
    var [orgGroup, setOrgGroup] = React.useReducer(rootReducer, {
        isLoaded: false,
        orgGroup: []
    });
    var [userName, setuserName] = React.useReducer(rootReducer, {
        isLoaded: false,
        userName: []
    });
    return (
        <OrgManagementContext.Provider value={{ org, setOrg,orgGroup, setOrgGroup, userName, setuserName}}>
            {children}
        </OrgManagementContext.Provider>
    );
};

const useOrgManagementState = () => {
    const context = React.useContext(OrgManagementContext);
    //console.log(context, OrgManagementContext)
    return context;
};

export function getOrg(dispatch,userDispatch, history) {

    var adminState = `${sessionStorage.getItem("isSalAdmin")}`
    switch(adminState){
        case 'true':
        return axios.get(`/${sessionStorage.getItem("organization")}/enterprise-org`).then(res => {           
            var apiResp = res.data.results
            apiResp.filter(f=> !f.isAccessible).forEach(resp=>{
                resp.isAccessible = true
            })
            dispatch({ type: "ORG_LIST", payload: apiResp });
        })
        case 'false':
        return axios.get(`/${sessionStorage.getItem("organization")}/users/${sessionStorage.getItem("email")}`).then(res => {
            let orgData = res.data.organizations;
            let orgArr = [];
            let orgReqArr = [];
            orgReqArr.push(axios.get(`/${sessionStorage.getItem("organization")}/enterprise-org`))

            axios.all(orgReqArr).then(axios.spread((...response) => {
                var apiResp = response[0].data.results
                apiResp.filter(f=> !f.isAccessible).forEach(resp=>{
                    resp.isAccessible = false
                })
                orgData.forEach(data=>{
                    apiResp.filter(b=> b.key === data).forEach(item =>{
                        item.isAccessible = true
                    })
                })
                orgArr = apiResp
                dispatch({ type: "ORG_LIST", payload: orgArr });
            }))
        })   
        default:
            return;
    }

}
export function getOrgGroups(dispatch,userDispatch, history) {
    return axios.get(`/${sessionStorage.getItem("organization")}/enterprise-org-groups`).then(res => {
        dispatch({ type: "ORG_GROUP_LIST", payload: res.data.results });
        // console.log('Org Group List', res.data.results)
    }).catch(err=>{
        //console.log(err);
    });
}
export function getuserNameById(dispatch,userDispatch, history) {
    return axios.get(`/${sessionStorage.getItem("organization")}/users`).then(res => {
        dispatch({ type: "USER_NAME", payload: res.data.results });
        // console.log('Org Name', res.data.results)
    }).catch(err=>{
        //console.log(err);
    });
}

export { OrgManagementProvider, OrgManagementContext, useOrgManagementState };
import React from "react";
import axios from "axios";

const DashboardContext = React.createContext();

const rootReducer = (state, action) => {
    switch (action.type) {
        case "ORG_LIST":
            return {
                isLoaded: true,
                org: action.payload
            };
        case "AUDIT_LIST":
            return {
                org: action.payload,
                isLoaded: true
            };
        case "USERS_LIST":
            return {
                isLoaded: true,
                user: action.payload
            };
    }
}
const DashboardProvider = ({ children }) => {
    var [org, setOrg] = React.useReducer(rootReducer, {
        isLoaded: false,
        org: []
    });
    var [audit, setAudit] = React.useReducer(rootReducer, {
        isLoaded: false,
        org: []
    });
    var [users, setUsers] = React.useReducer(rootReducer, {
        isLoaded: false,
        user: []
    });
    return (
        <DashboardContext.Provider value={{ org, setOrg, audit, setAudit, users, setUsers }}>
            {children}
        </DashboardContext.Provider>
    );
};

const useDashboardState = () => {
    const context = React.useContext(DashboardContext);
    return context;
};


function getRespData(orgData, dispatch) {
    var activeOrgCount = 0;
    var inactiveOrgCount = 0;
    var totalOrgCount = 0;
    var activeDevicesCount = 0;
    var alertDevicesCount = 0;
    var inactiveDevicesCount = 0;
    var totalDevicesCount = 0;
    let reqArr = []
    var tempArr = [];
    var tempArr1 = [];

    console.log("orgData--",orgData);
    if(orgData && orgData.length > 0){
        orgData.forEach(org => {
            switch (org.is_active) {
                case false:
                    return (
                        inactiveOrgCount = inactiveOrgCount + 1)
                case true:
                    return (
                        reqArr.push(axios.get(`/${org.key}/devices-health/`)),
                        tempArr.push({
                            name: org.name,
                            key: org.key
                        }),
                        activeOrgCount = activeOrgCount + 1
                    )
            }
        }) 
        totalOrgCount = activeOrgCount + inactiveOrgCount
    }
    else{
        dispatch({ type: "ORG_LIST", payload: tempArr1 })
    }

    axios.all(reqArr).then(axios.spread((...responses) => {
        responses.forEach((response, index) => {
            let res = response.data;
            activeDevicesCount = activeDevicesCount + res.active;
            alertDevicesCount = alertDevicesCount + res.alert;
            inactiveDevicesCount = inactiveDevicesCount + res.inactive
            totalDevicesCount = totalDevicesCount + res.total
            return tempArr1.push({
                ["name"]: tempArr[index].name,
                ["key"]: tempArr[index].key,
                ["total"]: res.total,
                ["active"]: res.active,
                ["inactive"]: res.inactive,
                ["alert"]: res.alert
            })
        })
        tempArr1.push({
            ["activeOrgs"]: activeOrgCount,
            ["inactiveOrgs"]: inactiveOrgCount,
            ["totalOrgs"]: totalOrgCount,
            ["activeDevices"]: activeDevicesCount,
            ["alertDevices"]: alertDevicesCount,
            ["inactiveDevices"]: inactiveDevicesCount,
            ["totalDevices"]: totalDevicesCount,
        })
        dispatch({ type: "ORG_LIST", payload: tempArr1 });
    }))
}

export function getOrg(dispatch, userDispatch, history) {

    var adminState = `${sessionStorage.getItem("isSalAdmin")}`
    console.log("GETORGG--",adminState);
    switch (adminState) {
        case 'true':
            return axios.get(`/${sessionStorage.getItem("organization")}/enterprise-org`).then(res => {
                getRespData(res.data.results, dispatch)
            })

        case 'false':
            return axios.get(`/${sessionStorage.getItem("organization")}/users/${sessionStorage.getItem("email")}`).then(res => {
                let orgData = res.data.organizations;
                let orgReqArr = [];
                orgData.map(orgDetail =>{
                    orgReqArr.push(axios.get(`/${sessionStorage.getItem("organization")}/enterprise-org/${orgDetail}`))
                })
                axios.all(orgReqArr).then(axios.spread((...response) => {
                    let respdata = []
                    response.forEach(data =>{
                      return respdata.push(data.data)
                    })
                    getRespData(respdata, dispatch)
                }))
            })
    }
}
export function getAudit(dispatch, userDispatch, history) {
    var readOnlyState = `${sessionStorage.getItem("isReadOnly")}`
    switch (readOnlyState) {
        case 'true':
            return []
        case 'false':
            return axios.get(`/${sessionStorage.getItem("organization")}/auditlog?limit=10`).then(res => {
                let userData = res.data.results;
                dispatch({ type: "AUDIT_LIST", payload: userData });
    
            }).catch(err => {
                console.log(err);
            });
    }
}
export function getUsersList(dispatch, userDispatch, history) {

    var adminCount = 0;
    var limitedAdminCount = 0;
    var readOnlyCount = 0;
    var resultArr = [];
    return axios.get(`/${sessionStorage.getItem("organization")}/users`).then(res => {
        let userData = res.data.results;
        var totalCount = 0;
        if (userData) {
            var userArr = []
            userData.forEach(u=>{
                if (u.is_admin === true) {
                    adminCount = adminCount + 1;
                }
                else if (u.is_limited_admin === true) {
                    limitedAdminCount = limitedAdminCount + 1;
                }
                else if (u.is_readonly === true) {
                    readOnlyCount = readOnlyCount + 1;
                }
                userArr.push({ name: u.first_name, email: u.user })
            })
            totalCount = adminCount + limitedAdminCount + readOnlyCount
            resultArr.push({ users: userArr, total: totalCount, admin: adminCount, limitedAdmin: limitedAdminCount, readOnly: readOnlyCount })
        }
        dispatch({ type: "USERS_LIST", payload: resultArr });

    }).catch(err => {
        console.log(err);
    });
}


export { DashboardProvider, DashboardContext, useDashboardState };               
